.structure.text {
    z-index: 1; /* text = 1; mouseRecordings = 2; tunnel = 3; users = 9; StructureWorkshop = 10; StylePane = 20; */
}

.structure.text textarea {
    min-width: 20px;
    font-size: 20px;
    overflow: hidden;
    resize: horizontal;
    background: rgba(255, 255, 255, 0.5);
    border: rgba(0,0,0,0.6) solid 1px;
}

pre.structure.text {
    padding: 2px;
    white-space: pre-wrap;
    font-size: 20px;
}

.structure.text button {
    cursor: pointer;
    border: rgba(0, 0, 0, 0.6) solid 1px;
    background: rgba(255, 255, 255, 0.5);
    font-weight: bold;
}