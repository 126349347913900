#StructureWorkshop {
    z-index: 10; /* text = 1; mouseRecordings = 2; tunnel = 3; users = 9; StructureWorkshop = 10; StylePane = 20; */
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: 20px 20px;
    background-image: linear-gradient(to right, rgba(0,0,0,0.2) 1px, rgba(255,255,255,0.05) 1px), linear-gradient(to bottom, rgba(0,0,0,0.2) 1px, rgba(255,255,255,0.05) 1px);
}

#StructureWorkshop.seeking-position {
    cursor: crosshair;
}

#StructureWorkshop.hidden {
    display: none;
}

#StructureWorkshop .structure-selector {
    position: absolute;
    border: solid white 1px;
    cursor: pointer;
    background: rgba(255,255,255,0.2);
}
#StructureWorkshop .structure-selector:hover {
    background: rgba(255,255,255,0.3);
}
#StructureWorkshop .close-button {
    position: absolute;
    right: 0;
    cursor: pointer;
    font-size: 18px;
    line-height: 20px;
    height: 20px;
    width: 20px;
    padding: 5px;
}