html {
    height: 100%;
    width: 100%;
}
body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    width: 100%;
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
}

#Toolbar {
    height: 40px;
}

#BelowToolbar {
    /* Change this value with the above value. */
    height: calc(100% - 40px);
    /* Allows absolute positioning within. */
    position: relative;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
