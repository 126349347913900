.structure.tunnel {
    z-index: 3; /* text = 1; mouseRecordings = 2; tunnel = 3; users = 9; StructureWorkshop = 10; StylePane = 20; */
    text-decoration: underline;
    cursor: pointer;
}
.tunnel-font {
    color: black;
    font-family: Lucida Console, Courier, monospace;
    font-size: 20px;
}

/* Edit Mode */
.tunnel-edit {}
.input-underline {
    display: block;
    border: none;
    color: #00000000;
    height: 1px;
    background: rgba(0,0,0,0.3);
}
.input-text-width-tester {
    position: absolute;
    visibility: hidden;
    height: auto;
    width: auto;
    white-space: nowrap;
}
input {
    border:none;
    background: rgba(255,255,255,0.6);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
textarea:focus, input:focus {
    outline: none;
}

.tunnel-edit button {
    cursor: pointer;
    border: rgba(0,0,0,0.6) solid 1px;
    background: rgba(255, 255, 255, 0.5);
    font-weight: bold;
}